import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="bg-indigo-700">
          <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              <span className="block">Thank you!</span>
              <span className="block"></span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">
            Message received! We'll get back to you as soon as possible.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
